<template>
  <div class="createLimit">
    <div class="title">
      {{
        type == "add" || type == "copy"
          ? "创建新的店内限时选购"
          : type == "edit"
          ? "编辑限时选购"
          : "限时选购详情"
      }}
    </div>
    <div class="cell">
      <div class="titles">基本信息</div>
      <div style="margin-left: 200px">
        <div style="display: flex; align-items: center">
          <div style="margin-right: 20px; width: 70px; text-align: right">
            时间段
          </div>
          <div
            @click="
              type != 'info' &&
              (form.status_txt != '进行中的活动' || type == 'copy')
                ? handleOpen()
                : ''
            "
            v-if="times"
          >
            <el-input
              style="width: 260px"
              readonly
              size="small"
              placeholder="请选择日期"
              v-model="times"
              :disabled="
                type == 'info' ||
                (form.status_txt == '进行中的活动' && type != 'copy')
              "
            >
              <i slot="prefix" class="el-input__icon el-icon-date"></i>
            </el-input>
          </div>
          <div class="time-btn" @click="handleOpen" v-else>
            <i class="el-icon-date"></i>
            选择时间段
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px">
          <div style="margin-right: 20px; width: 70px; text-align: right">
            商品标准
          </div>
          <div
            style="
              width: 600px;
              border: 1px solid #eee;
              padding: 20px 10px;
              border-radius: 4px;
            "
          >
            <div
              style="
                color: #ee4d2d;
                border: 1px solid #ee4d2d;
                display: initial;
                padding: 4px 14px;
                border-radius: 4px;
                font-size: 14px;
              "
            >
              全部
            </div>
            <div style="margin: 20px 0 10px; font-size: 14px">全部商品标准</div>
            <div style="display: flex; flex-wrap: wrap">
              <div
                style="
                  font-size: 14px;
                  display: flex;
                  width: 48%;
                  margin-bottom: 10px;
                  color: #b0b0b0;
                  margin-right: 10px;
                "
                v-for="item in standard"
                :key="item.id"
              >
                <div
                  style="
                    width: 6px;
                    min-width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #ee4d2d;
                    margin-top: 6px;
                    margin-right: 10px;
                  "
                ></div>
                <span>{{ item.title }}：{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles">
        <div>店内限时选购商品</div>
        <div v-if="goodsList.length != 0 && type != 'info'">
          <el-button
            style="min-width: 120px; color: #ee4d2d; border-color: #ee4d2d"
            size="small"
            icon="el-icon-plus"
            @click="addGoods"
            plain
            >添加商品</el-button
          >
        </div>
      </div>
      <div
        v-if="goodsList.length == 0 && type != 'info'"
        style="font-size: 12px; color: #b0b0b0; margin-top: 4px"
      >
        在添加商品至促销活动前请阅读商品标准，以减小添加商品的错误率。
      </div>
      <div
        v-else
        style="
          font-size: 12px;
          color: #b0b0b0;
          margin-top: 4px;
          margin-bottom: 20px;
        "
      >
        您已为此限时选购时间段开启了10中的{{
          form.open_goods_num ? form.open_goods_num : 0
        }}个商品。
      </div>
      <div v-if="goodsList.length == 0" style="margin-top: 20px">
        <el-button
          style="min-width: 120px; color: #ee4d2d; border-color: #ee4d2d"
          size="small"
          icon="el-icon-plus"
          @click="addGoods"
          plain
          >添加商品</el-button
        >
      </div>

      <!-- 添加后的列表 -->
      <div class="goods-list" v-if="goodsList.length != 0">
        <div class="goods-list_top" v-if="type != 'info'">
          <div class="top-l">
            <div style="margin-right: 140px">
              <div>批次设定</div>
              <div style="font-size: 12px; margin-top: 4px">
                <!-- {{ checkedCities.length }}件商品已选 -->
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div>
                <div>折扣</div>
                <div style="margin-top: 10px">
                  <el-input
                    style="width: 140px"
                    size="small"
                    v-model="percentage"
                    placeholder="1-99"
                    @input="inputZKAll()"
                  >
                    <template slot="append">%折扣</template>
                  </el-input>
                </div>
              </div>
              <div style="margin: 0 20px">
                <div>活动库存</div>
                <div
                  style="margin-top: 10px; display: flex; align-items: center"
                >
                  <!-- <el-select
                    style="width: 120px"
                    size="small"
                    v-model="inventory"
                    placeholder="请选择"
                    @change="invChangeAll"
                  >
                    <el-option label="不更新" :value="1"> </el-option>
                    <el-option label="无限制" :value="2"> </el-option>
                    <el-option label="设定限制" :value="3"> </el-option>
                  </el-select> -->
                  <el-input
                    style="width: 120px"
                    size="small"
                    @input="inputKCAll"
                    v-model="inventoryVal"
                  ></el-input>
                </div>
              </div>
              <div>
                <div>购买限制</div>
                <div style="margin-top: 10px">
                  <el-select
                    style="width: 120px"
                    size="small"
                    v-model="purchasing"
                    placeholder="请选择"
                    @change="purChangeAll"
                  >
                    <el-option label="不更新" :value="1"> </el-option>
                    <el-option label="无限制" :value="2"> </el-option>
                    <el-option label="设定限制" :value="3"> </el-option>
                  </el-select>
                  <el-input
                    style="width: 80px"
                    size="small"
                    v-if="purchasing == 3"
                    v-model="purchasingVal"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="top-r">
            <el-button size="small" @click="handleUpdate" type="info" plain
              >更新全部</el-button
            >
            <el-button @click="handleOpenAll(1)" size="small" type="info" plain
              >开启</el-button
            >
            <el-button @click="handleOpenAll(2)" size="small" type="info" plain
              >关闭</el-button
            >
            <el-button @click="handleDelAll" size="small" type="info" plain
              >删除</el-button
            >
          </div>
        </div>
        <div class="goods-list_head">
          <div style="width: 3%" v-if="type != 'info'">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >
            </el-checkbox>
          </div>
          <div style="width: 11%">商品名称</div>
          <div style="width: 11%">原价</div>
          <!-- <div style="width: 11%">折扣金额</div> -->
          <div style="width: 11%">售价</div>
          <div style="width: 11%">折扣</div>
          <div style="width: 14%">活动库存</div>
          <div style="width: 11%">库存</div>
          <div style="width: 14%">购买限制</div>
          <div style="width: 11%">开启/关闭</div>
          <div style="width: 3%; text-align: center" v-if="type != 'info'">
            操作
          </div>
        </div>
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <div
            class="goods-list_content"
            v-for="(item, index) in goodsList"
            :key="item.id"
          >
            <div class="content-t">
              <div style="width: 3%" v-if="type != 'info'">
                <el-checkbox
                  :indeterminate="item.isIndeterminate"
                  v-model="item.checkAll"
                  :label="item.id"
                  @change="handleCheckAllChange1(item, $event)"
                  >&ensp;</el-checkbox
                >
              </div>
              <div style="width: 68%; display: flex; align-items: center">
                <el-image
                  style="width: 40px; height: 40px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
                <div
                  class="single-hidden"
                  style="width: 300px; font-size: 14px"
                >
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 14%">
                <el-select
                  size="small"
                  style="width: 100px"
                  v-model="item.buyer_limitType"
                  @change="purChange(item)"
                  placeholder="请选择"
                  :disabled="type == 'info'"
                >
                  <el-option label="无限制" :value="1"> </el-option>
                  <el-option label="设定限制" :value="2"> </el-option>
                </el-select>
                <el-input
                  @input="change"
                  style="width: 60px"
                  size="small"
                  v-if="item.buyer_limitType == 2"
                  v-model="item.buyer_limit"
                ></el-input>
              </div>
              <div style="width: 11%">
                <el-switch
                  @change="changeKG(item)"
                  v-model="item.kq"
                  active-color="#13ce66"
                  :disabled="type == 'info'"
                >
                </el-switch>
              </div>
              <div style="width: 3%" v-if="type != 'info'">
                <div
                  style="
                    width: 36px;
                    height: 36px;
                    border: 1px solid #eee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 16px;
                    background: #fff;
                  "
                  @click="handleDel(index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <el-checkbox-group
              v-model="item.list"
              @change="handleCheckedCitiesChange1(item)"
            >
              <div
                class="content-b"
                v-for="(val, i) in item.specification"
                :key="i"
              >
                <div style="width: 3%" v-if="type != 'info'">
                  <el-checkbox
                    @change="handleCheckAllChange2(item, $event)"
                    :label="val.id"
                    >&ensp;</el-checkbox
                  >
                </div>
                <div style="width: 11%; font-size: 14px">
                  {{ val.difference }}
                </div>
                <div style="width: 11%; font-size: 14px">RM{{ val.price }}</div>
                <div style="width: 11%; display: flex; align-items: center">
                  <el-input
                    style="width: 100%"
                    size="small"
                    @input="inputJG(val, $event)"
                    v-model="val.zksj"
                    :disabled="type == 'info'"
                  >
                    <template slot="prepend">RM</template>
                  </el-input>
                  <span style="margin: 0 4px; font-size: 12px">或</span>
                </div>
                <div style="width: 11%">
                  <el-input
                    @change="changeZK(val)"
                    @input="inputZK(val, $event)"
                    style="width: 80%"
                    size="small"
                    v-model="val.zkbl"
                    placeholder="1-99"
                    :disabled="type == 'info'"
                  >
                    <template slot="append">%折扣</template>
                  </el-input>
                </div>
                <div style="width: 14%">
                  <el-input
                    style="width: 100px"
                    size="small"
                    @input="inputKC(val, $event)"
                    v-model="val.cxStock"
                    :disabled="type == 'info'"
                  ></el-input>
                </div>
                <div style="width: 11%; font-size: 14px">
                  {{ val.stock }}
                </div>
                <div style="width: 14%"></div>
                <div style="width: 11%">
                  <el-switch
                    @change="change"
                    v-model="val.switch"
                    active-color="#13ce66"
                    :disabled="type == 'info'"
                  >
                  </el-switch>
                </div>
                <div style="width: 3%" v-if="type != 'info'"></div>
              </div>
            </el-checkbox-group>
          </div>
        </el-checkbox-group>
      </div>
    </div>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
      "
      v-if="type != 'info'"
    >
      <el-button @click="handleBack" size="small" plain>取消</el-button>
      <el-button @click="addLimit" size="small" type="primary">确认</el-button>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
      "
      v-else
    >
      <el-button @click="handleBack" style="min-width: 140px" size="small" plain
        >返回列表</el-button
      >
    </div>

    <el-dialog
      title="选择店内限时选购的时间段"
      :visible.sync="dialogVisible1"
      width="1000px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="display: flex; margin-top: -20px">
        <div>
          <div style="margin-bottom: 10px">日期</div>
          <el-calendar
            :first-day-of-week="7"
            style="width: 500px; border: 1px solid #eee"
            v-model="value"
          >
            <template slot="dateCell" slot-scope="{ date, data }">
              <!-- <p :class="data.isSelected ? 'is-selected' : ''">
                {{ data.day.split("-").slice(1).join("-") }}
                {{ data.isSelected ? "✔️" : "" }}
              </p> -->
              <div style="text-align: center" class="asdf">
                <div
                  :class="
                    tian(data.day) >= currentTime && tian(data.day) <= afterTime
                      ? 'gfdg'
                      : ''
                  "
                >
                  {{ data.day.split("-").slice(1)[1] }}
                </div>
                <div
                  class="bvc"
                  style="
                    font-size: 10px;
                    margin-top: 10px;
                    transform: scale(0.8);
                  "
                  v-if="
                    tian(data.day) >= currentTime && tian(data.day) <= afterTime
                  "
                >
                  7个时段
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div>
          <div style="margin-bottom: 10px">时段</div>
          <div
            style="
              width: 450px;
              padding-bottom: 36px;
              border: 1px solid #eee;
              border-left: none;
              min-height: 490px;
            "
          >
            <div
              style="
                height: 53px;
                padding: 12px 0;
                display: flex;
                align-items: center;
                background: #f6f6f6;
                font-size: 15px;
              "
            >
              <div style="width: 50%; padding-left: 40px">时段</div>
              <div style="width: 50%">商品</div>
            </div>
            <div v-if="timeShow">
              <el-radio-group v-model="radio" style="width: 100%">
                <div
                  style="
                    padding: 10px 20px;
                    align-items: center;
                    display: flex;
                    height: 50px;
                    border-bottom: 1px solid #eee;
                    width: 100%;
                  "
                  v-for="(item, i) in timeData"
                  :key="i"
                >
                  <el-radio
                    :disabled="item.type"
                    :label="i"
                    style="position: relative"
                  >
                    {{ item.date }}
                    <span
                      v-if="i == 7"
                      style="
                        transform: scale(0.8);
                        font-size: 12px;
                        position: absolute;
                        left: 147px;
                        top: -6px;
                        z-index: 13;
                        color: #b0b0b0;
                      "
                      >+1</span
                    >

                    <span style="margin-left: 60px"
                      >剩余限额：10</span
                    ></el-radio
                  >
                </div>
              </el-radio-group>
            </div>
            <div
              style="text-align: center; color: #b0b0b0; line-height: 200px"
              v-else
            >
              暂无
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <MarketingGoods ref="goods" :show="dialogVisible"></MarketingGoods>
  </div>
</template>
<script>
import MarketingGoods from "@/components/goods/MarketingGoods.vue";
import {
  getGoodsStandard,
  limitPurchaseAction,
  limitPurchaseDetails,
  limitPurchaseList,
} from "@/api/marketing.js";
export default {
  components: { MarketingGoods },
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      value: "",
      radio: "0",
      type: "add",
      time: "",
      times: "",
      timeShow: false,
      timeData: [
        { date: "00:00:00-09:00:00", fa: "00:00-09:00", num: 10, type: false },
        { date: "09:00:00-12:00:00", fa: "09:00-12:00", num: 10, type: false },
        { date: "12:00:00-14:00:00", fa: "12:00-14:00", num: 10, type: false },
        { date: "14:00:00-16:00:00", fa: "14:00-16:00", num: 10, type: false },
        { date: "16:00:00-20:00:00", fa: "16:00-20:00", num: 10, type: false },
        { date: "20:00:00-22:00:00", fa: "20:00-22:00", num: 10, type: false },
        { date: "22:00:00-00:00:00", fa: "22:00-00:00", num: 10, type: false },
      ],
      currentTime: "",
      afterTime: "",
      standard: [],
      form: {
        id: 0,
        start_time: "",
        end_time: "",
        limit_goods: [],
      },
      inventoryVal: "",
      goodsList: [],
      isIndeterminate: false,
      checkAll: false,
      checkedCities: [],
      percentage: "", //折扣
      inventory: 1, //活动库存状态
      purchasing: 1, //购买限制状态
      purchasingVal: "",
    };
  },
  created() {
    this.afterTime = Date.parse(new Date()) + 86400000 * 9;
    this.currentTime = Date.parse(new Date()) - 86400000;
    this.getStandard();
    if (sessionStorage.LIMITTYPE) {
      this.type = sessionStorage.LIMITTYPE;

      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createLimit") {
              val.meta.bread[3].name =
                this.type == "edit"
                  ? "编辑限时选购"
                  : this.type == "add" || this.type == "copy"
                  ? "创建新的店内限时选购"
                  : "限时选购详情";
            }
          });
        }
      });

      // 是否是编辑操作
      if (this.type == "edit" || this.type == "info" || this.type == "copy") {
        this.getInfo();
      }
    } else {
      this.$router.replace("limit");
    }

    // this.timeData.forEach((item) => {
    // });
  },
  watch: {
    value: function (newVal) {
      let time = new Date(newVal).getTime();
      if (time >= this.currentTime && time <= this.afterTime) {
        this.timeShow = true;
      } else {
        this.timeShow = false;
      }
      this.radio = "";
      let ks = parseInt((newVal.getTime() - 3600000 * 8) / 1000);
      let js = parseInt(ks + 3600 * 24);
      this.getList(ks, js);
      this.time = this.switchDate(newVal);
    },
  },
  methods: {
    getInfo() {
      limitPurchaseDetails({
        id: sessionStorage.LIMITID,
      }).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          if (this.type == "copy") {
            this.form.id = 0;
          }
          this.times = this.form.deal_time;
          let arr = [];
          this.form.limit_goods.forEach((item, index) => {
            this.checkedCities.push(item.goods_id);
            this.checkAll = true;
            arr.push({
              id: item.goods_id,
              image: item.goods_image,
              title: item.goods_name,
              buyer_limit: item.buyer_limit,
              buyer_limitType: item.buyer_limit ? 2 : 1,
              kq: item.status == 1 ? true : false,
              list: [],
              specification: [],
            });
            this.form.limit_goods[index].discountGoodsConfig.forEach((val) => {
              arr[index].list.push(val.goods_sku_id);
              arr[index].specification.push({
                difference: val.difference ? val.difference : "-",
                id: val.goods_sku_id,
                cxStock: val.stock_limit,
                stock: val.stock,
                price: val.goods_price,
                zksj: val.discount_price,
                zkbl: parseInt(val.discount_ratio),
                switch: val.status == 1 ? true : false,
              });
            });
          });
          this.goodsList = arr;
        }
      });
    },
    getList(ks, js) {
      let arr = [];
      limitPurchaseList({
        status: 1,
        limit_time: ks + "-" + js,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          res.data.list.forEach((item) => {
            arr.push(item.deal_time.split(" ")[1]);
          });
          limitPurchaseList({
            status: 2,
            limit_time: ks + "-" + js,
            page: this.page,
            page_size: this.page_size,
          }).then((res) => {
            if (res.code == 1) {
              res.data.list.forEach((item) => {
                arr.push(item.deal_time.split(" ")[1]);
              });
              let dqTime = Date.now();
              this.timeData.forEach((val) => {
                let ksTime = new Date(
                  this.time + " " + val.date.split("-")[0]
                ).getTime();
                // 判断已有活动和活动时间是否超过当前时间
                if (arr.indexOf(val.fa) != -1 || ksTime < dqTime) {
                  val.type = true;
                } else {
                  val.type = false;
                }
              });
              this.$forceUpdate();
            }
          });
        }
      });
    },
    // 获取全部标准
    getStandard() {
      getGoodsStandard().then((res) => {
        if (res.code == 1) {
          this.standard = res.data;
        }
      });
    },
    addLimit() {
      let arr = [];
      this.goodsList.forEach((item, index) => {
        arr.push({
          goods_id: item.id,
          buyer_limit: item.buyer_limit,
          status: item.kq ? 1 : 2,
          sku: [],
        });
        this.goodsList[index].specification.forEach((val) => {
          arr[index].sku.push({
            sku_id: val.id,
            discount_type: val.zkbl ? 2 : 1,
            discount: val.zkbl ? val.zkbl : val.zksj,
            stock_limit: val.cxStock,
            status: val.switch ? 1 : 2,
          });
        });
      });
      this.form.limit_goods = arr;
      limitPurchaseAction({ ...this.form }).then((res) => {
        if (res.code == 1) {
          this.$message.success(
            this.type == "add" ? "创建限时选购成功" : "编辑限时选购成功"
          );
          this.$router.replace("limit");
        }
      });
    },
    handleClose() {
      this.dialogVisible1 = false;
    },
    // 添加商品
    addGoods() {
      this.dialogVisible = true;
    },
    switchDate(standardTime) {
      if (standardTime != "") {
        let a = standardTime;
        var Month = a.getMonth() + 1;
        var MonthDate;
        var DayDate = a.getDate();
        var Day;
        if (Month < 10) {
          MonthDate = "0" + Month;
        } else {
          MonthDate = Month;
        }
        if (DayDate < 10) {
          Day = "0" + DayDate;
        } else {
          Day = DayDate;
        }
        var datetime = a.getFullYear() + "-" + MonthDate + "-" + Day;
        return datetime;
      }
    },
    // 批次设定库存
    invChangeAll() {
      if (this.inventory == 2) {
        this.inventoryVal = 0;
      } else {
        this.inventoryVal = "";
      }
    },
    // 限购时段确认
    handleConfirm() {
      if (!this.time) {
        this.$message.error("请选择限时抢购日期");
        return false;
      }
      if (this.radio === "") {
        this.$message.error("请选择限时抢购时段");
        return false;
      }
      this.times = this.time + " " + this.timeData[this.radio].date;
      this.form.start_time =
        new Date(
          this.time + " " + this.timeData[this.radio].date.split("-")[0]
        ).getTime() / 1000;
      this.form.end_time =
        new Date(
          this.time + " " + this.timeData[this.radio].date.split("-")[1]
        ).getTime() / 1000;
      if (this.radio == 6) {
        this.form.end_time = this.form.end_time + 86400;
      }
      this.dialogVisible1 = false;
    },
    tian(val) {
      return new Date(val).getTime();
    },
    change() {
      this.$forceUpdate();
    },
    changeKG(val) {
      val.specification.forEach((item) => {
        if (val.kq) {
          item.switch = true;
        } else {
          item.switch = false;
        }
      });
      this.$forceUpdate();
    },
    // 每个商品的促销限购
    purChange(val) {
      if (val.buyer_limitType == 1) {
        val.buyer_limit = 0;
      } else {
        val.buyer_limit = "";
      }
      this.$forceUpdate();
    },
    purChangeAll() {},
    // 更新全部
    handleUpdate() {
      this.goodsList.forEach((item) => {
        if (this.purchasing == 2) {
          item.buyer_limitType = 1;
          item.buyer_limit = 0;
        }
        if (this.purchasing == 3) {
          item.buyer_limitType = 2;
          item.buyer_limit = this.purchasingVal;
        }
        item.specification.forEach((val) => {
          if (item.list.indexOf(val.id) != -1) {
            if (this.percentage) {
              val.zkbl = this.percentage;
              val.zksj = ((val.price * this.percentage) / 100).toFixed(2);
            }
            if (this.inventoryVal) {
              val.cxStock = this.inventoryVal;
            }

            this.$forceUpdate();
          }
        });
      });
    },
    // 打开关闭
    handleOpenAll(type) {
      this.goodsList.forEach((item) => {
        if (this.checkedCities.indexOf(item.id) != -1) {
          item.kq = type == 1 ? true : false;
        }
        item.specification.forEach((val) => {
          if (item.list.indexOf(val.id) != -1) {
            val.switch = type == 1 ? true : false;
          }
        });
      });
      this.$forceUpdate();
    },
    // 删除
    handleDel(index) {
      if (this.checkedCities.indexOf(this.goodsList[index].id) != -1) {
        this.checkedCities.splice(
          this.checkedCities.indexOf(this.goodsList[index].id),
          1
        );
      }
      this.goodsList.splice(index, 1);
      this.$refs.goods.$data.checkedCities.splice(index, 1);
    },
    // 删除全部
    handleDelAll() {
      let arr = this.goodsList.filter((item) => {
        if (this.checkedCities.indexOf(item.id) == -1) {
          return item;
        }
      });
      this.goodsList = arr;
      this.checkedCities = [];
      this.$refs.goods.$data.checkedCities = [];
      this.goodsList.forEach((item) => {
        this.$refs.goods.$data.checkedCities.push(item.id);
      });
      this.checkAll = false;
      this.isIndeterminate = false;
    },
    // 选中商品
    handleAndGoods(arr) {
      let list = [];
      this.goodsList.forEach((item) => {
        list.push(item.id);
      });

      arr.forEach((item) => {
        if (list.indexOf(item.id) == -1) {
          this.checkedCities.push(item.id);
          this.checkAll = true;
          item.buyer_limitType = 1;
          item.buyer_limit = 0;
          item.kq = true;
          item.list = [];
          item.isIndeterminate = false;
          item.checkAll = false;
          item.specification;
          if (item.specification.length == 0) {
            item.list[0] = 0;
            // 无规格商品
            item.specification.push({
              difference: "-",
              id: 0,
              price: item.price,
              sales: 0,
              sn: "",
              stock: item.stock,
              zksj: "",
              zkbl: "",
              // cxStockType: 1,
              cxStock: "",
              switch: true,
              list: [],
            });
          } else {
            item.specification.forEach((val) => {
              // 有规格商品
              item.list.push(val.id);
              val.zksj = "";
              val.zkbl = "";
              // val.cxStockType = 1;
              val.cxStock = "";
              val.switch = true;
            });
          }
          this.goodsList.push(item);
        }
      });
      this.dialogVisible = false;
    },
    // 单规格售价
    changeZK(val) {
      if (val.zkbl) {
        val.zksj = ((val.price * val.zkbl) / 100).toFixed(2);
        this.$forceUpdate();
      } else {
        val.zksj = "";
      }
    },
    // 全选
    handleCheckAllChange(val) {
      let arr = [];
      this.goodsList.forEach((item) => {
        if (val) {
          arr.push(item.id);
        }
        this.handleCheckAllChange1(item, val);
      });

      this.checkedCities = arr;
      this.isIndeterminate = false;
    },
    // 全选规格
    handleCheckAllChange1(data, val) {
      let arr = [];
      if (val) {
        data.specification.forEach((item) => {
          arr.push(item.id);
        });
      } else {
        arr = [];
      }

      data.list = arr;
      data.isIndeterminate = false;
      this.$forceUpdate();
    },
    handleCheckAllChange2(a, b) {
      if (b) {
        if (this.checkedCities.indexOf(a.id) == -1) {
          this.checkedCities.push(a.id);
        }
      }
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.goodsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.goodsList.length;
    },
    handleCheckedCitiesChange1(data) {
      let checkedCount = data.list.length;
      data.checkAll = checkedCount === data.specification.length;
      data.isIndeterminate =
        checkedCount > 0 && checkedCount < data.specification.length;
      if (checkedCount == 0) {
        this.checkedCities.splice(this.checkedCities.indexOf(data.id), 1);
      }
      this.handleCheckedCitiesChange(this.checkedCities);
      this.$forceUpdate();
    },
    handleBack() {
      this.$router.go(-1);
    },
    inputJG(item, val) {
      if (val.indexOf("-") != -1 || val == 0) {
        item.zksj = "";
      }
      this.$forceUpdate();
    },
    inputZK(item, val) {
      var reg = /^([1-9]\d|\d)$/;
      if (!reg.test(val) || val == 0) {
        item.zkbl = "";
      } else {
        item.zkbl = val;
      }
      this.$forceUpdate();
    },
    inputZKAll() {
      var reg = /^([1-9]\d|\d)$/;
      if (!reg.test(this.percentage) || this.percentage == 0) {
        this.percentage = "";
      }
      this.$forceUpdate();
    },
    inputKC(item, val) {
      if (val <= 0 || val == "-") {
        item.cxStock = "";
      }
      this.$forceUpdate();
    },
    inputKCAll() {
      if (this.inventoryVal <= 0 || this.inventoryVal == "-") {
        this.inventoryVal = "";
      }
      this.$forceUpdate();
    },
    handleOpen() {
      this.dialogVisible1 = true;
    },
  },
};
</script>
<style lang="less">
.createLimit {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
  }
  .cell {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .time-btn {
      border: 1px solid #ee4d2d;
      border-radius: 4px;
      padding: 6px 14px;
      color: #ee4d2d;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .goods-list {
      font-size: 14px;
      color: #666;
      .goods-list_top {
        border: 1px solid #eee;
        padding: 20px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        .top-l {
          width: 70%;
          display: flex;
          align-items: center;
        }
      }
      .goods-list_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: #f6f6f6;
        margin-top: 20px;
        border-radius: 4px;
        border: 1px solid #eee;
        font-size: 14px;
      }
      .goods-list_content {
        margin-top: 20px;
        border: 1px solid #eee;
        .content-t {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          background: #fafafa;
        }
        .content-b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 20px;
        }
      }
    }
  }
  .el-calendar-table .el-calendar-day {
    height: 60px;
  }
  .el-calendar-table td.is-today {
    color: #66cc00;
  }
  .el-calendar-table .el-calendar-day:hover {
    background: #ff9966;
    color: #fff !important;
  }
  // .asdf {
  //   color: #ee4d2d;
  // }
  .gfdg {
    color: #ee4d2d;
  }
  .bvc {
    color: #ee4d2d;
  }
  .asdf:hover .bvc {
    color: #fff;
  }
  .asdf:hover .gfdg {
    color: #fff;
  }
  .el-calendar-table td.is-selected {
    background: #ee4d2d;
    color: #fff !important;
    .gfdg {
      color: #fff;
    }
    .bvc {
      color: #fff;
    }
  }
  .el-calendar-table tr td:first-child {
    border: none;
  }
  .el-calendar-table tr:first-child td {
    border: none;
  }
  .el-calendar-table td {
    border: none;
  }
  .el-calendar-table:not(.is-range) td.next,
  .el-calendar-table:not(.is-range) td.prev {
    color: #fff;
    pointer-events: none;
  }
  .el-calendar__body {
    padding: 12px 20px 20px;
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */
  }
  .next {
    opacity: 0;
  }
  .prev {
    opacity: 0;
  }
  .el-input-group__prepend {
    padding: 0 10px;
  }
  .el-input-group__append {
    padding: 0 10px;
  }
}
</style>